import { Button } from '@mui/material';
import React from 'react';
import CloudDownloadIcon from '@mui/icons-material/CloudDownload';
import fileToDownload from '../files/MOH child policy firmato.pdf'


const DownloadFileButton = () => {
    // buttonStyle is a round button with a black shadow and very small linear gradient from green to forest green
    const buttonStyle = {
        borderRadius: 50,
        backgroundColor: 'green',
        color: 'white',
        padding: '10px 20px',
        fontSize: '1.2em',
        fontWeight: 'bold',
        backgroundImage: 'linear-gradient(90deg, green, darkgreen)',
        transition: 'all 0.3s',
        margin:30
    }

    return <>
        <a
            className="App-link"
            href={fileToDownload}
            target="_blank"
            rel="noopener noreferrer"
        >
            <Button style={buttonStyle}>
                <CloudDownloadIcon size='large' sx={{ padding: 1 }} /> Download
            </Button>
        </a></>
}

export default DownloadFileButton;