import { Box } from '@mui/material';
import logo from '../img/logo_transp.png';
import React from 'react';
import background from '../img/fullbody3.jpg';
import DownloadFileButton from '../components/DownloadFileButton'


export const WorkinProgress = () => {


    console.log("WorkinProgress");
    return (
        <Box sx={{
            backgroundSize: "cover",
            backgroundImage: `url(${background})`,
        }}>
            <Box sx={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                justifyContent: 'center',
                height: '100vh',
                color: 'white',
                width: '100%',
                backdropFilter: 'blur(90px)',
                backgroundRepeat: "no-repeat",
            }}>
                <img src={logo} className="App-logo" alt="logo" />
                {/* <Typography variant='h4' color={"black"}>Work in progress</Typography> */}
                <DownloadFileButton />

            </Box>
        </Box>
    );
}