import './App.css';

import { useEffect, useState } from 'react';
import { LanguageContext } from './multilang/LanguageContext';
import { Languages } from './multilang/Languages';
import { FloatingLanguageSelector } from './components/LanguageSelectors';
import HomePage from './pages/HomePage';
import { WorkinProgress } from './pages/WorkInProgress';

function App() {
  console.log("language stored: ", sessionStorage.getItem("language_id") ? "set to " + sessionStorage.getItem("language_id") : "not set")
  const [currentLanguage, setCurrentLanguage] = useState(Languages.ENG)

  useEffect(() => {
    if (!currentLanguage) {
      console.log("Setting new language in local storage: ", currentLanguage)
      sessionStorage.setItem("language_id", Languages.ENG)
    }
  }, [currentLanguage])


  const workInProgress = true // <------------- switch per pubblicare il sito vero

  return (

    <>
      {
        workInProgress ?
          <WorkinProgress />
          :
          <LanguageContext.Provider value={[currentLanguage, setCurrentLanguage]}>
            <HomePage />
            <FloatingLanguageSelector />
          </LanguageContext.Provider>
      }

    </>
  );
}

export default App;